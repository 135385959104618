

















import Vue from "vue";
import CartRecentSales from "@/views/Dashboard/Finantial/ChartRecentSales.vue";
import ChartLeads from "@/views/Dashboard/Finantial/ChartLeads.vue";
// import NextApponments from "./CharNextApponments.vue";

export default Vue.extend({
  components: { CartRecentSales, ChartLeads },
  name: "dashboard-finantial",
});
