




/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import Chart, { ChartType } from "chart.js/auto";

const guid = (): string => {
  const string4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };

  //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
  return (
    string4() +
    string4() +
    "-" +
    string4() +
    "-" +
    string4() +
    "-" +
    string4() +
    "-" +
    string4() +
    string4() +
    string4()
  );
};

export default Vue.extend({
  name: "chart-base",
  props: {
    width: {
      type: Number,
      default: () => 100,
    },
    height: {
      type: Number,
      default: () => 100,
    },
    chartType: String,
    chartData: {
      // {
      //   labels: ["label 1", "label 2", "label 3", "label 4"],
      //   datasets: [
      //     {
      //       label: "Dataset 1",
      //       data: [0, 20, 15, 60, 60],
      //       borderColor: "#ff0000",
      //     },
      //   ],
      // }
      type: [Object, String],
      required: true,
    },
    chartOptions: {
      type: [Object, String],
      required: true,
    },
  },
  data() {
    return {
      canvasId: "",
      chart: null,
    };
  },
  mounted() {
    this.canvasId = `canvas-${guid()}`;
    this.$nextTick(() => {
      this.chartConstructor();
    });
  },
  methods: {
    chartConstructor(/*chartType: string, chartData: any, chartOptions: any*/) {
      const ctx = (document as any)
        .getElementById(this.canvasId)
        .getContext("2d");

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (this as any).chart = new Chart(ctx, {
        type: this.chartType as ChartType,
        data: this.chartData,
        options: this.chartOptions,
      });
    },
    /**
     * Add a new dataset to the chart
     *  const newDataset = {
     *    label: 'Dataset ' + (data.datasets.length + 1),
     *    backgroundColor: Utils.transparentize(dsColor, 0.5),
     *    borderColor: dsColor,
     *    borderWidth: 1,
     *    data: Utils.numbers({count: data.labels.length, min: -100, max: 100}),
     *  }
     */
    addDataset(data: any) {
      (this as any).chart.data.datasets.push(data);
    },
    update(data: any) {
      (this as any).chart.data = data;
    },
  },
});
