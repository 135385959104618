<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-card min-height="540" :loading="loading">
      <v-card-title class="d-flex align-center py-1">
        <div class="text-h6 font-weight-regular text--primary">
          Leads Summary
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <template v-if="!loading">
        <v-card-text>
          <v-row column>
            <v-col cols="12" sm="6">
              <template v-if="emptyData && loading">
                <div>cargando</div>
              </template>
              <template v-else-if="emptyData && !loading">
                <div>no data</div>
              </template>
              <template v-else>
                <chart-base
                  :height="200"
                  :width="200"
                  ref="chartleads"
                  chart-type="pie"
                  :chartData="chartData"
                  :chartOptions="chartOptions"
                />
              </template>
            </v-col>
            <v-col cols="12" sm="12">
              <template>
                <span class="d-flex justify-center font-weight-bold"
                  >Converted Leads</span
                >
                <v-data-table
                  :headers="headers"
                  :items="converteds"
                  :items-per-page="5"
                  class="elevation-1"
                >
                  <template v-slot:[`item.fullname`]="{ item }">
                    {{ item.patient ? item.patient.fullname : "" }}
                  </template>
                  <template v-slot:[`item.date`]="{ item }">
                    {{ item.patient ? getDate(item.patient.createAt) : "" }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="primary"
                          icon
                          @click="gotopatient(item)"
                          ><v-icon>mdi-eye</v-icon></v-btn
                        >
                      </template>
                      <span>View {{ getpatientN }} Details</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
      </template>
      <template v-else>
        <div class="loading">
          <v-progress-circular
            :size="70"
            :width="7"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
      </template>
    </v-card>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { apiBaseUrl } from "@/enviorment";
import ChartBase from "./ChartBase.vue";
import { utilMixin } from "@/utils/mixins";
import { mapGetters } from "vuex";
export default {
  mixins: [utilMixin],
  components: { ChartBase },
  name: "chart-leads",
  data() {
    return {
      loading: false,
      totallead: 0,
      totalconvertedpercent: 0,
      totalnotconvertedpercent: 0,

      converteds: [],
    };
  },
  computed: {
    ...mapGetters(["getpatientN"]),
    headers() {
      return [
        {
          text: "Lead name",
          value: "name",
          align: "start",
          sortable: false,
        },
        {
          text: "Phone",
          value: "phone",
          align: "start",
          sortable: false,
        },
        {
          text: this.getpatientN + " name",
          value: "fullname",
          align: "start",
          sortable: false,
        },
        {
          text: "Converted date",
          value: "date",

          sortable: false,
        },
        {
          text: "",
          value: "actions",

          sortable: false,
        },
      ];
    },
    chartData() {
      return {
        labels: [
          "Converted (" + this.totalconvertedpercent + "%)",
          "Not converted (" + this.totalnotconvertedpercent + "%)",
        ],
        datasets: [
          {
            label: "",
            data: [],
            backgroundColor: ["#2196f3", "#F12541"],
          },
        ],
      };
    },
    chartOptions() {
      return {
        responsive: true,
        plugins: {
          legend: {
            position: "bottom",
          },
          title: {
            display: true,
            text: "Total Leads (" + this.totallead + ")",
          },
        },
      };
    },

    emptyData() {
      if (this.chartData.datasets[0].data.length == 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    async getLeadsTopatient() {
      try {
        this.loading = true;
        const res = (await getAPI.get(apiBaseUrl + "/social/getLeadConverted"))
          .data;
        this.loading = false;
        this.totallead = res.total;
        this.converteds = res.converted;
        this.totalconvertedpercent = (
          (res.convertedCount * 100) /
          this.totallead
        ).toFixed(2);
        this.totalnotconvertedpercent = 100 - this.totalconvertedpercent;
        this.chartData.datasets[0].data.push(res.convertedCount);
        this.chartData.datasets[0].data.push(res.total - res.convertedCount);
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },

    gotopatient(item) {
      const patient = item.patient;
      this.$router.push(`/patients/details/${patient.uuid}`);
    },
  },
  mounted() {
    this.getLeadsTopatient();
  },
};
</script>
<style lang="scss" scoped>
.pie {
  border: 1px solid red;
  max-height: 400px;
}
.loading {
  min-height: 540px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
</style>
